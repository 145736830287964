function getParams() {
  const params = new URLSearchParams(document.location.search.substring(1));

  return {
    brand: params.get('brand'),
    destination: params.get('destination'),
    language: params.get('language'),
    contentId: params.get('contentId')
  };
}

document.addEventListener('DOMContentLoaded', () => {
  const container = document.querySelector('#container');
  const params = getParams();
  const configOverride = {
    playerConfiguration: {
      autoPlayStrategy: "never"
    },    
    advertisingConfiguration: {
      hierarchyConfiguration: {
        adUnit: {
          playerType: 'embedded'
        }
      }
    },
    analyticsConfiguration: {
      braze: { enabled: false },
      moat: { enabled: false },
      comscore: { enabled: false },
      mparticle: { enabled: false },
      permutive: { enabled: false },
      newRelic: { enabled: false }
    }
  };

  new Jasper.Player(
    {
      container,
      brand: params.brand,
      destination: params.destination,
      playbackLanguage: params.language,
      brandConfigurationEnvironment: 'production',
      brandConfigurationOverride: configOverride
    },
    params.contentId
  );
});
